.home{
    width: 100%;
}

.searchContainer{
    background: var(--white);
    box-shadow: -2px 2px 5px rgba(0,0,0,0.10);
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: -250px;
    /* z-index: 99; */
    padding: 10px;
}


/* mobile devices query */
@media only screen and (max-width: 768px){
    .searchContainer{
        width: 90%;
    }
    
    
}

@media only screen and (max-width: 480px){
    .searchContainer{width: 90%; margin-top: -60px;}
}