.hotel {
    width: 100%;
    margin-top: 50px;
    overflow: hidden;
}

.hotel-enjoy {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    background: #f8f9fa;
    padding: 2rem 4rem;
}

.hotelCol-left,
.hotelCol-right {
    width: 50%;
    padding: 20px;
    margin: auto;
}

.hotel h1 {
    margin-bottom: 30px;
    line-height: 55px;
}

.hoteldivider {
    width: 150px;
    height: 2px;
    background: var(--primaryColor);
}

.hoteldivider.middle {
    width: 100px;
    height: 2px;
    background: var(--primaryColor);
}



.hotelDesc {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    margin-top: 20px;
}

.hotelRight {
    align-self: flex-start;
}

.hotelRight>h1 {
    text-align: center;
}

.hotelLeft {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

}

.hotelBoxLeft {
    margin-bottom: 30px;
}

.hotelBoxLeft,
.hotelBoxRight {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
}

.serviceCol {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
}


.hotelLeft {
    flex: 0.5;
    display: flex;
    flex-direction: row;
    gap: 20px;
}


.hotelRight {
    flex: 0.5;
}

.serviceIcons {
    color: var(--primaryColor);
}

.serviceTitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: #000000;
}

.hotel-branch {
    margin-top: 50px;
    width: 100%;
    padding: 2rem 4rem;
    overflow: hidden;
}

.hotel-branch h1 {
    text-align: center;
}
.hotel-img-wrapper{
    margin-bottom: 50px;
}
.hotel-box-wrap{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(350px, auto));
    justify-content: center;
    gap: 2rem;
}

.hotel-box{
    position: relative;
    cursor: pointer;
}
.hotel-box-img{
    width: 350px;
    height: 100%;
    overflow: hidden;
}
.hotel-box-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.hotel-box-img img:hover{
    transform: scale(1.1);
    transition: 1s;
}
.hotel-box-text{
    position: absolute;
    left: 1rem;
    bottom: 1rem;
}

.hotel-box-text h2{
    color: white;
}
.hotel-box-link{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 10px;
    background: var(--primaryColor);
    color: white;
    cursor: pointer;
}
/* mobile devices query */

@media only screen and (max-width: 991px),
only screen and(max-device-width: 991px) {

    .hotelBoxLeft,
    .hotelBoxRight {
        flex-direction: column;
    }
    .hotel-branch{
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 768px) {

    .hotel-enjoy {
        padding: 0 1rem;
    }
    .hotel-branch{
        padding: 0 1rem;
    }
    .hotel-box-wrap{
        grid-template-columns: repeat(auto-fit,minmax(310px, auto));
    }
    
    .hotel-box-img{
        width: 310px;
    }
}


@media only screen and (max-width: 480px) {
    .hotel {
        margin-top: 50px;
    }

    .hotel h1 {
        font-size: 30px;
        margin-bottom: 20px;
        line-height: 45px;
    }

    .hotel-enjoy {
        flex-direction: column;
    }

    .hotelCol-left,
    .hotelCol-right {
        width: 100%;
        padding: 0;
    }
    .hotelWrapper {
        flex-direction: column-reverse;
    }

    .hotelRight,
    .hotelLeft {
        flex: 1;
        gap: 0;
    }

    .hotelBoxLeft,
    .hotelBoxRight {
        padding: 5px;
        width: 100%;
    }

    .serviceCol {
        flex-direction: column;
    }
    
    /* .hotel-box-wrap{
        grid-template-columns: repeat(auto-fit,minmax(400px, auto));
    }
    .hotel-box-img{
        width: 400px;
    } */
}