.dashboard{
    width: 100%;
    height: 100vh;
    padding: 2rem 4rem;
}

.dashboardWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}
.dashboardLeft{
    flex: 0.25;
    background: white;
    box-shadow: -2px 5px 15px rgba(0,0,0,0.10);
    width: 100%;
    height: 100vh;
    border-radius: 10px;
}
.dashboardRight{
    flex: 0.75;
    background: white;
    width: 100%;
    height: 100vh;
}
.dashboardRight p{
    margin-top: 10px;
}

.dashboardBook{
    margin-top: 30px;
}

.dashboardBook span{
    font-size: 20px;
    font-weight: 800;
    font-style: normal;
}

.dashboardBookDetail{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

}
.dashboardBookDetail .bookedCol{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}

.bookBox{
    padding: 5px;
    border-right: 2px solid lightgray;
}
.bookBox span{
    font-size: 16px;

}


