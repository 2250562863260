.login{
    width: 100%;
    height: 100vh;
    position:relative;
    padding: 2rem 4rem;
}
.login-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background:white;
    padding: 30px;
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.login-logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}
.login-header-title{
    margin-top: 10px;
    margin-bottom: 10px;
}
.login-header-title h2{
    font-weight: 700;
    line-height: 40px;
}
.login-form-wrapper{
    min-width: 20rem;
    height:fit-content;
    background: white;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: .5rem;
    padding: 20px;
}

.login-container form input{
    width: 100%;
    height: 3rem;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    color: #232323;
    margin: 15px auto;
    border: 0.1rem solid #6c6b6b;
}
.login-container form button{
    width: 100%;
    background: var(--primaryColor);
    padding: 1rem 3.0rem;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    cursor: pointer;
}
.login-copy{
    margin-top: 50px;
    text-align: center;
}
.login-copy span{
    font-size: 0.913rem;
    padding: 10px;
}
.login-error{
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}