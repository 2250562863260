.slideContainer{
    width:100%;
    position: relative;
    z-index: -5;
   
}
.swiper{
    position: relative !important;
    cursor:pointer;
  
}
.overlay{
    position: absolute;
    top:0;
    left: 0;
    z-index: 5;
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 99%;
}
.swiper-slider{
    position: relative;
}
.swiperContent{
    position: absolute;
    top: 30%;
    left: 10%;
    width: 60%;
}

.swiperTitle{
    font-size: 60px;
    font-weight: 700;
    font-style:normal;
    line-height: 55px;
    color: white;
    text-shadow: -2px 5px 5px #000000;
}

.swiperDesc{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: 50%;
    margin-top: 20px;
    text-shadow: -2px 5px 5px #000000;
    color: black;
}

.swiper-slider >img{
    /* border-radius: 30px !important; */
    object-fit: cover;
    width:100%;
    height: 100%;
    cursor: pointer;
}

.swiper-pagination{
    display:none;
}

.swiper-button-next{
    display: none !important;
}
.swiper-button-prev{
    display: none !important;
}



/* mobile devices query */
@media only screen and (max-width: 480px){
    .swiperContent{
        top: 10%;
        width: 70%;
    }
    .swiperTitle{
        font-size: 30px;
        line-height: none;
    }
    .swiperDesc{
        width: 100%;
        margin-top: 5px;
        font-size: 14px;
    }
}
