.computer{
    width: 100%;
    background-image: url("../../../../public/assets/serviceImages/computer-centre.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height:200px;
    background-color: rgba(8, 3, 1, 0.472);
    background-blend-mode: overlay;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.computer h1{
    padding: 2rem 8rem;
}

.computerWrapper{
    margin-top: 100px;
    padding: 0rem 8rem;
}
.computerWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.computerDetails{
    margin-top: 50px;
    margin-bottom: 50px;
}
.computerDetails span{
    padding: 10px 20px;
    background-color: var(--primaryColor);
    color: white;
    font-weight: 500;
}

.computerDetails p{
    margin-top: 20px;
}



@media only screen and (max-width:991px){
    .computer h1{
        padding: 1rem 2rem;
        font-size: 30px;
    }
    
    .computerWrapper{
        margin-top: 50px;
        padding: 0rem 2rem;
    }
}

@media only screen and (max-width:768px){
    .computer{
        height: 100px;
    }
}