.about{
    width: 100%;
    background-image: url("../../../public/assets/slideImages/slide2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height:150px;
    background-color: rgba(8, 3, 1, 0.472);
    background-blend-mode: overlay;
    color: white;
}

.about h1{
    padding: 2rem 8rem;
}

.aboutWrapper{
    margin-top: 100px;
    padding: 0rem 8rem;
}

.aboutWrapper h1{
    text-align: center;
    margin-bottom: 20px;
}

.aboutContent{
    margin-top: 20px;
    margin-bottom: 20px;
}
.aboutContent .col{
    margin-bottom: 20px;
}

.aboutContentTwo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.aboutLeft, .aboutRight{
    flex: 1 1 40%;
}
.aboutRight ul li{
    list-style: none;
}

.aboutValue{
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.aboutValue .aboutValueBox{
    flex: 1 1 40%;
}


/* mobile view query */
@media only screen and (max-width: 991px){
    .about h1{
        padding: 2rem 2rem;
    }
    .aboutWrapper{
        padding: 1rem 2rem;
    }
}

@media only screen and (max-width: 640px){
    .aboutWrapper{
        margin-top: 50px;
    }
    .aboutWrapper h1{
       line-height: 42px;
    }
}

@media only screen and (max-width: 480px){
    .about h1{
       font-size: 30px;
    }
    .aboutWrapper h1{
        font-size: 30px;
     }
    .aboutContentTwo {
        flex-direction: column;
    }
    .aboutLeft h2, .aboutRight h2, .aboutValueBox h2{
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .aboutValue{
        flex-direction: column;
        align-items: flex-start;
    }
}