@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-padding-top: 4rem;
}

:root{
  font-family: 'Poppins', sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

--font-size: 16px;
--primaryColor: #092879;
--white: #ffffff;
--black: #000000;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

code {
  font-family: var(--font-family);
}

p{
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

h1{
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
}

.link{
  color:inherit !important;
  text-decoration: none !important;
}

ul li{
  list-style: none;
}

.loading{
  font-size: 30px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

