.yenagoa{
    width: 100%;
    background-image: url("../../../../public/assets/slideImages/slide2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height:200px;
    background-color: rgba(8, 3, 1, 0.472);
    background-blend-mode: overlay;
    color: white;
}

.yenagoa h1{
    padding: 2rem 8rem;
}
.yenagoa p{
    padding: 0rem 8rem;
}

.yenagoaWrapper{
    margin-top: 100px;
    padding: 0rem 8rem;
}

.yenagoaWrapper h1{
    text-align: center;
    margin-bottom: 20px;
}

.yenagoaRoomWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:20px;
}
.yenagoaLeft, .yenagoaRight{
    flex: 1 1 40rem;
}
.yenagoaBox{
    width: 400px;
    height: 300px;
    border: 2px solid white;
    box-shadow: -2px 5px 5px rgba(0,0,0,0.10);
    background: white;
    transition: all .2s ease;
}
.yenagoaBoxImage{
    width:400px;
    height: 300px;
    cursor: pointer;
    overflow: hidden;
}
.yenagoaBoxImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .5s ease;
}
.yenagoaBox:hover img{
    transform: scale(1.5);
}
.yenagoaBoxText{
    background: white;
    padding: 10px;
}

.yenagoaBoxText button{
    border:none;
    outline: none;
    padding: 15px 30px;
    background: var(--primaryColor);
    color: white;
    cursor: pointer;
}

.footerSec{
    margin-top: 200px;
}

.vip-card-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(24rem, auto));
    grid-gap: 1.5rem;

}
.vip-card{
    overflow: hidden;
    transition: 5s ease;
    cursor: pointer;
}
.vip-card img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    transition: .5s ease;
}
.vip-card:hover img{
    transform: scale(1.1);
}
.vip-card button{
    padding: 15px 30px;
    background-color: var(--primaryColor);
    font-size: 16px;
    font-style: normal;
    color:white;
    font-weight: 400;
    outline: none;
    border: none;
    transition: .5s ease;
    cursor: pointer;
    margin: 5px auto;
}
.vip-card-body span{
    font-size: 20px;
    font-weight: 800;
    font-style: normal;
    line-height: 24px;
}
.vip-card-body p{
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 22.2px;
}

/* media query */
@media (max-width:991px) {
    .yenagoa h1 {
        padding: 1rem 2rem;
        font-size: 30px;
    }
    .yenagoa p {
        padding: 0rem 2rem;
    }
    .yenagoa {
        height: 100px;
    }

    .yenagoaWrapper {
        margin-top: 50px;
        padding: 0rem 2rem;
    }

    .yenagoaBox{
        max-width: 350px;
        height: 350px;
    }
    .yenagoaBoxImage{
        height: 200px;
    }
  
}



@media (max-width:768px) {

    .yenagoaBox{
        max-width: 300px;
        height: 350px;
    }
    .yenagoaBoxImage{
        height: 200px;
    }
  
}



@media (max-width:640px) {
    .yenagoa h1{
        font-size: 25px;
    }
    .yenagoaBox{
        max-width: 400px;
        height: 350px;
    }
    .yenagoaBoxImage{
        height: 220px;
    }
  
}