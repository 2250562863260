
.success{
    position: relative;
    width: 100%;
    text-align: center;
}
.successWrapper{
    width: 90%;
    max-width: 500px;
    max-height: 550px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    background: white;
    box-shadow: -2px 5px 5px rgba(0,0,0,0.10);
    padding: 20px 50px 70px;
}

.successIcon{
    margin-bottom: 20px;
    border: 1px solid #006400;
    width: max-content;
    padding: 10px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}
.shomeBtn{
    margin-top: 10px;
    border: none;
    outline: none;
    background: var(--primaryColor);
    color: white;
    padding: 15px 50px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}