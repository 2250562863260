.yenagoapage{
    width: 100%;
    overflow: hidden;
}
.yenagoa {
    width: 100%;
    background-image: url("../../../../public/assets/slideImages/slide2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 200px;
    background-color: rgba(8, 3, 1, 0.472);
    background-blend-mode: overlay;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.yenagoa h1 {
    padding: 2rem 8rem;
}

.yenagoa p {
    padding: 0rem 8rem;
}

.yenagoaWrapper {
    margin-top: 100px;
    padding: 0rem 2rem;
}

.yenagoaWrapper h1 {
    text-align: center;
    margin-bottom: 20px;
}

.yenagoaRoomWrapper {
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

.yenagoaBox {
    width: 200px;
    background: white;
    transition: all .2s ease;
    margin-bottom: 50px;
}

.yenagoaBoxImage {
    cursor: pointer;
    overflow: hidden;
}

.yenagoaBoxImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all .5s ease;
}

.yenagoaBox:hover img {
    transform: scale(1.5);
}

.yenagoaBoxText {
    background: white;
    padding: 10px;
}

.yenagoaBoxText button {
    border: none;
    outline: none;
    padding: 15px 30px;
    background: var(--primaryColor);
    color: white;
    cursor: pointer;
}

.footerSec {
    margin-top: 200px;
}








/* NEW STYLES */
.yenagoa-room-container{
    padding: 1rem 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 10px;
}
.yenagoa-box{
    margin: 20px auto;
    width: 100%;
}

.yenagoa-image{
    max-width: 350px;
    height: auto;
    cursor: pointer;
}

.yenagoa-image > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
}


.yengagoa-btn{
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    background: var(--primaryColor);
    color: #fff;
    font-weight: 500;
    padding: 12px 25px;
    border-radius: 4px;
    margin: 12px auto;
}
/* media query */
@media (max-width:991px) {
    .yenagoa h1 {
        padding: 1rem 2rem;
        font-size: 30px;
    }
    .yenagoa p {
        padding: 0rem 2rem;
    }
    .yenagoa {
        height: 100px;
    }

    .yenagoaWrapper {
        margin-top: 50px;
        padding: 0rem 2rem;
    }

    .yenagoaBox{
        max-width: 350px;
        height: 350px;
    }
    .yenagoaBoxImage{
        height: 200px;
    }
  
}



@media (max-width:768px) {
    .yenagoa-room-container{
        padding: 1rem 1rem;
        display: grid;
        grid-template-columns:auto;

    }
    .yenagoaBox{
        max-width: 300px;
        height: 350px;
    }
    .yenagoaBoxImage{
        height: 200px;
    }
  
}



@media (max-width:640px) {
    .yenagoa h1{
        font-size: 25px;
    }
    .yenagoaBox{
        max-width: 400px;
        height: 350px;
    }
    .yenagoaBoxImage{
        height: 220px;
    }
  
}