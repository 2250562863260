.booking{
    width: 100%;
    background-image: url("../../../public/assets/slideImages/slide2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height:200px;
    background-color: rgba(8, 3, 1, 0.472);
    background-blend-mode: overlay;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.booking h1{
    padding: 2rem 8rem;
}

.bookingWrapper{
    margin-top: 100px;
    padding: 0rem 8rem;
}

.bookingWrapper h1{
    text-align: center;
    margin-bottom: 20px;
}


.bookingContainer{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap:20px;
}

.bookingLeft{
    flex:0.35;
}
.bookingRight{
    flex:0.65;
}
.bookingBox{
    width: 350px;
    height: auto;
    background: white;
    box-shadow: -2px 5px 5px rgba(0,0,0,0.10);
    border-radius: 30px;
    padding: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    cursor: pointer
}
.bookingBox span{
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 10px;
}
.bookingImg{
    width: 320px;
    height:200px;
    overflow: hidden;
}

.bookingImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.bookingContent{
    text-align: center;
    margin-top: 20px;
}
.bookingContent p{
    font-weight: bold;
    line-height: 24px;
}
.bookingIcon{
    color: var(--primaryColor);
    cursor: pointer;
}
.bookingQty{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.bookingQty .qtyBtn{
    display: flex;
    align-items: center;
    gap: 15px;
}
.bookingTotal{
    margin-top: 20px;
    background: var(--primaryColor);
    color: white;
    padding: 10px;
    border-radius: 5px;
}
.bookingForm{
    width: 100%;
    margin-top: 20px;
}
.bookingForm .bookingFormCol{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}
.formGroup.address{
    flex: 1;
}
.formGroup{
    width: 100%;
    flex-basis: 48%;
    margin-top: 10px;
}
 .formGroup input, textarea{
        width: 100%;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        border: 1px solid lightgray;
        resize: none;
}
.formGroup.select select{
    border: 1px solid lightgray;
}
.formGroup select{
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500px;
}

.countrySection{
    display: flex;
    align-items: center;
    gap: 10px;
}
.formGroup.textarea{
    flex: 1;
}

.bookingForm button{
    border: none;
    outline: none;
    padding: 20px 40px;
    width:max-content;
    background: var(--primaryColor);
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    margin-top: 30px;
}


@media only screen and (max-width: 991px){
    .booking h1{
        padding: 1rem 2rem;
        font-size: 30px;
    }
    
    .bookingWrapper{
        margin-top: 50px;
        padding: 1rem 2rem;
    }
}

@media only screen and (max-width: 768px){
    .booking{
        height: 100px;
    }
    .bookingContainer{
        flex-direction: column;
    }
    .bookingLeft{
        align-self: center;
    }
    .bookingLeft h2{  
       text-align: center;
    }
    .bookingRight{
        margin-top: 30px;
    }
}