.error-page{
    width: 100%;
    height: 100%;
  
   
}
.errorWrapper{
    max-width: 500px;
    max-height: 550px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
  margin-top: 50px;
}

.errorWrapper h1{
    margin-top: 50px;
    margin-bottom: 30px;
}

.errorWrapper button{
    outline: none;
    border: none;
    background: var(--primaryColor);
    padding: 20px 20px;
    color: white;
    margin-top: 50px;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    cursor: pointer;
}