.dashboardSidebar{
    width: 100%;
    height: 100%;
    padding: 10px;
  
}

.dsidebarWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
}

.sidebarLogo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}
.dsidebarLink{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    padding: 20px;
    max-width: 300px;
}
.dsidebarLink span{
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
}

.dsidebarLogout{
   position: absolute;
   bottom: 20%;
   padding: 20px;
}

.dsidebarLogout button{
    padding: 15px 60px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    background: var(--primaryColor);
    color: white;
}

.linkTwo{
    width: 200px;
    background: rgb(243, 243, 243);
    padding: 10px;
    color: black;
    text-decoration: none;
}