.restaurant{
    width: 100%;
    background-image: url("../../../../public/assets/serviceImages/rest.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height:200px;
    background-color: rgba(8, 3, 1, 0.472);
    background-blend-mode: overlay;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.restaurant h1{
    padding: 2rem 8rem;
}

.resWrapper{
    margin-top: 100px;
    padding: 0rem 8rem;
}
.resWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.resDetails{
    margin-top: 50px;
    margin-bottom: 50px;
}
.resDetails span{
    padding: 10px 20px;
    background-color: var(--primaryColor);
    color: white;
    font-weight: 500;
}

.resDetails p{
    margin-top: 20px;
}

@media only screen and (max-width:991px){
    .restaurant h1{
        padding: 1rem 2rem;
        font-size: 30px;
    }
    
    .resWrapper{
        margin-top: 50px;
        padding: 0rem 2rem;
    }
}

@media only screen and (max-width:768px){
    .restaurant{
        height: 100px;
    }
}