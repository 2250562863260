.welcome {
    width: 100%;
    padding: 2rem 4rem;
    margin-top: 200px;
}

.welcome h1 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 50px;
}

.divider {
    width: 150px;
    height: 2px;
    background: var(--primaryColor);
    margin-left: auto;
    margin-right: auto;
}

.divider.middle {
    width: 100px;
    height: 2px;
    background: var(--primaryColor);
    margin-left: auto;
    margin-right: auto;
}

.welcomeWrapper {
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
}

.welcomeCol {
    padding: 20px;
}

.welcomeCol.Left {
    position: relative;
}

.imgSec {
    width: 350px;
    height: 350px;
    margin: auto;
    overflow: hidden;
    border-radius: 50%;
    transition: all 0.5 ease;
    animation: bounce 5s linear infinite;

}

@keyframes bounce {
    50% {
        transform: translateY(0);
    }

    80% {
        transform: translateY(-25px);
    }

    100% {
        transform: translateY(0px);
    }

}

.welcomeCol.Left::after {
    position: absolute;
    content: '';
    top: 20px;
    left: -10px;
    width: 193px;
    height: 193px;
    background: var(--primaryColor);
    border-radius: 50%;
    mix-blend-mode: multiply;
}

.welcomeCol img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}



.welcomeWrapper .welcomeCol {
    flex: 1 1 40%;
}

.welcomeCol span {
    color: var(--primaryColor);
    font-weight: 300;
}

.welcomeCol h3 {
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
    line-height: 41px;
    color: var(--black);
    margin-bottom: 10px;
}

.welcomeCol button {
    outline: none;
    border: none;
    padding: 15px 60px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    background: var(--primaryColor);
    color: white;
    margin-top: 20px;
    cursor: pointer;
    transition: all .5s ease;
}

.welcomeCol button:hover {
    /* background: var(--primaryColor);
    color: var(--white); */
    transform: translateY(-10px);
}



/* mobile devices query */
@media only screen and (max-width: 768px) {
    .welcome {
        padding: 2rem 1rem;
    }

    .welcome h1 {
        line-height: 42px;
    }

    .welcomeWrapper {
        flex-direction: column-reverse;
    }

    .welcomeCol.Left {
        width: 100%;
        margin-top: 30px;
    }
    
}


@media only screen and (max-width: 480px) {
    .welcome {
        margin-top: 0px;
    }

    .welcome h1 {
        font-size: 33px;
        line-height: 45px;
        margin-bottom: 20px;
    }

    .welcomeWrapper {
        flex-direction: column-reverse;
    }

    .welcomeCol button {
        padding: 15px 60px;
    }

    .welcomeCol.Left::after {
        top: 230px;
        left: 200px;
        width: 120px;
        height: 120px;
    }

    .imgSec {
        width: 300px;
        height: 300px;

    }
    .welcomeCol{
        padding: 0;
    }
}