
.dashboardSearch{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}
.currentUser{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}
.dashboardSearchForm{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 80%;
    background: white;
    box-shadow: -2px 5px 5px rgba(0,0,0,0.10);
    padding: 10px;
    border-radius: 10px;
}
.dashboardSearchForm input{
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
}