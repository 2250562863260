.dashboard{
    width: 100%;
    height: 100vh;
    padding: 2rem 4rem;
}

.dashboardWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}
.dashboardLeft{
    flex: 0.15;
    background: white;
    box-shadow: -2px 5px 15px rgba(0,0,0,0.10);
    width: 100%;
    height: 100vh;
    border-radius: 10px;
}
.dashboardRight{
    flex: 0.85;
    background: white;
    width: 100%;
    height: 100vh;
}
.dashboardRight p{
    margin-top: 10px;
}

.dashboardBook{
    margin-top: 30px;
}

.dashboardBook span{
    font-size: 20px;
    font-weight: 800;
    font-style: normal;
}

.dashboardBookDetail{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.dashboardBookDetail .bookedCol{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.bookBox{
    padding: 5px;
    border-right: 2px solid lightgray;
}
.bookBox span{
    font-size: 16px;

}


.dashboardInput{
    display:flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 10px;
    
}

.roomBtn{
    border:none;
    outline: none;
    padding: 15px 60px;
    background: var(--primaryColor);
    font-size: 16px;
    font-weight: 800;
    font-style: normal;
    color: white;
    margin-top: 20px;
    width: max-content;
    cursor: pointer;
}
.formGroup .formImageBox{
    width: 350px;
    height: 250px;
    border: 2px dotted lightgray;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    padding: 8px;
}
.formImageText{
    background: lightgray;
    position: absolute;
    width: 95%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.formImageText span{
    opacity: 0.5;
}
.formGroup .formImageBox img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.upload{
    display: none;
}