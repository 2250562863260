.search{
padding: 20px;
}

.searchWrapper{
    width: 100%;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 10px;
}

.searchLeft{
    flex: 1;
    display: flex;
    align-items:center;
    justify-content: center;
    gap: 5px;
    width: 100%;
}
.options{
    display: flex;
    align-items: center;
    gap: 10px;
}
.options span:nth-child(1){
    background: none;
    color: var(--black);
    font-weight: 400;
    font-style: normal;
}
.options span{
    background: var(--primaryColor);
    color: var(--white);
    border-radius: 10px;
    padding: 2px 10px;
    font-size: 14px;
    font-weight: 300;
}
.searchWrapper input, select{
    width: 90%;
    padding: 8px 10px;
    outline: none;
    /* border: 1px solid lightgray; */
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;

}

.searchContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.searchBox{
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: 90%;
    cursor: pointer;
    padding: 5px;
    transition: all .5s;
}
.searchBox:hover{
    background: var(--primaryColor);
    color: white;
}
.sBoxLeft{
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 10px;
}
.searchBox img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sBoxRight{
    display: flex;
    flex-direction: column;
}
.sBoxRight span{
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
}
/* 
.searchRight{
    display: flex;
    gap: 15px;
}
.searchRight label{
    margin-right: 10px;
}

.searchWrapper button{
    outline: none;
    border: none;
    background: var(--primaryColor);
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
} */



/* mobile devices query */
@media only screen and (max-width: 480px){
    .swiperContent{
        top: 10%;
        width: 70%;
    }
}