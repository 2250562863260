.navbar {
    width: 100%;
    padding: 0rem 4rem;
    background: white;
    position: sticky;
    min-height: 60px;
    top: 0;
    z-index: 99;
}

.logo{
    max-width: 200px;
    min-height: 70px;
    border: none !important
}
.logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border: none !important;
}
.navbarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    cursor: pointer;

}

.linkText:hover {
    color: var(--primaryColor);
}


.links button {
    background: var(--primaryColor);
    color: var(--white);
    padding: 12px 30px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
}

.hotelDropDown {
    position: absolute;
    top: 80px;
}

.serviceDropDown {
    display: flex;
    align-items: center;
    position: relative;
    color: black;
    transition: all 500ms ease;
}

.serviceDropDown:hover .serviceDropMenu {
    display: flex;
}

.serviceDropMenu {
    position: absolute;
    display: none;
    top: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: white;
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.10);
    padding: 15px;
    border-radius: 10px;
    color: black;
    width: 180px;
}

.serviceText {
    transition: all 5s ease;
}

.serviceText:hover {
    color: var(--primaryColor);
    width: 100%;
    padding: 5px;
}

.mobileBtn {
    display: none;
    cursor: pointer;
}

.mobileMenu {
    display: none;
}

/* mobile devices query */
@media only screen and (max-width: 991px) {
    .navbar {
        padding: 0rem 1rem;
    }

    .mobileBtn {
        display: block;
    }

    .menu {
        display: none;
    }

    .mobileMenu {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        background: white;
        gap: 15px;
        width: 100%;
        height: 100%;
        padding: 20px 20px;
        transition: 0.2s;
    }
    .close-btn{
        position: absolute;
        right: 40px;
        cursor: pointer;
    }
    .mobileMenu button{
        outline: none;
        border: none;
        padding: 15px 60px;
        background: var(--primaryColor);
        color: white;
        cursor: pointer;
        font-size: 16px;
        margin-top: 50px;
    }
    .mobileMenu span{
        font-size: 1rem;
    }
    .mobileMenu span:hover{
       color: var(--primaryColor);
    }
    .mobile-link-text {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        gap: 5px;
    }
    
}

@media only screen and (max-width: 480px) {
    .navbar {
        padding: 0rem 1rem;
    }
    .logo{
        max-width: 160px;
    }
}