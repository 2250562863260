.gallery{
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 2rem 4rem;
}
.gallery >h1{
    text-align: center;
}

.gDivider{
    margin: 30px 20px;
}
.galleryWrapper{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.galleryBox{
    width: 350px;
    height: 250px;
    border-radius: 20px;
    margin: auto;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all .5s;
    
}
.galleryBox:hover{
    background: var(--primaryColor);
}
.galleryBox:hover .gImage{
    opacity: 0.2;
    z-index: 0;
}
.gImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: relative;
    
}

.gText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    color: white;
    
}


/* mobile devices query */


@media only screen and (max-width:640px){
    .gallery{
        padding: 0 1rem;
    }
    .galleryBox{
        width: 250px;
        height: 150px;
    }
}
@media only screen and (max-width: 480px){
    .gallery{
        margin-top: 50px;
        padding: 0rem 1rem;
    }
    .gallery>h1
        {font-size: 30px;}
    
    .galleryWrapper{
        padding: 10px;
    }
    .galleryBox{
        width: 400px;
        height: 200px;
    }
}