.dashboard{
    width: 100%;
    height: 100vh;
    padding: 2rem 4rem;
    overflow: hidden;
}

.dashboardWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}
.dashboardLeft{
    flex: 0.15;
    background: white;
    box-shadow: -2px 5px 15px rgba(0,0,0,0.10);
    width: 100%;
    height: 100vh;
    border-radius: 10px;
}
.dashboardRight{
    flex: 0.85;
    background: white;
    width: 100%;
    height: 100vh;
}
.dashboardRight p{
    margin-top: 10px;
}


.dashboardRoomDetails{
    height: 100%;
    overflow-y:scroll;
}
.dashboardRoomWrapper{
    margin-top: 20px;
    margin-bottom: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
}

.roomWrapper{
    width: 250px;
    height: 300px;
    background-color: white;
    box-shadow: -2px 5px 5px rgba(0,0,0,0.10);
    border-radius: 5px;
    margin-bottom: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all 500ms ease;
}
.roomDetails{
    width: 250px;
    height: 150px;
    margin: auto;
}
.roomDetails img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.roomContent{
    padding: 5px;
}

.roomActionBtn p {
    background: var(--primaryColor);
    color: white;
    border-radius: 5px;
    width: max-content;
    padding: 0px 20px;
 
}

.roomWrapper:hover .roomActionBtn{
    display: flex;
}
