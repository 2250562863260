.footer{
    width: 100%;
    margin-top: 100px;
    background: black;
    padding: 2rem 4rem;
}
.footerWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.footerLeft, .footerRight, .footerBottom{
    flex: 1;
}

.footerLeft h1{
    font-size: 20px;
    font-weight: 'bold';
    font-style: normal;
    line-height: normal;
    color: white;
    text-justify:distribute-all-lines;
}

.footerRight{
    background-color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}

.footerRight h2{
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    color: var(--primaryColor);
    margin-bottom: 30px;
}

.footerRightContent{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}
.footerAddress{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 25px;
}
.footerAddressContent span{
    text-transform: uppercase;
    font-weight: 600;
    font-style: normal;
}
.footerImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}
.footerIcons{
    color: var(--primaryColor);
}

.footerBottom{
    background-color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    color: var(--white);
    margin-top: 50px;
    bottom: 0;
    border-radius: 30px;
}
.developer{
    font-weight:bold;
    cursor: pointer !important;
    font-size: 12px !important;
}

/* mobile devices query */

@media only screen and (max-width:768px){
    .footer{
        padding: 0 1rem;
    }
    .footerWrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
    }
    .footerImage{
        width: 250px;
        height: 350px;
    }
}

@media only screen and (max-width:640px){
    .footer{
        padding: 0 1rem;
    }
    .footerWrapper{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
   
}

@media only screen and (max-width: 480px){
    .footer{
        padding: 2rem 1rem;
    }
    
    .footerWrapper{
       flex-direction: column;
    }
    .footerBottom{
        text-align: center;
    }
    .footerImage{
        align-self: center;
    }
}